<template>
  <div class="editor">
    <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :show-file-list="false" :before-upload="beforeUpload" :http-request="getUploadUrl" style="height: 0px;"></el-upload>
    <quill-editor v-model="childValue" :options="editorOption" ref="QuillEditor"></quill-editor>
  </div>
</template>

<script>
  import axios from 'axios'
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], //引用，代码块
    [{
      'header': 1
    }, {
      'header': 2
    }], // 几级标题
    [{
      'list': 'ordered'
    }, {
      'list': 'bullet'
    }], // 有序列表，无序列表
    [{
      'script': 'sub'
    }, {
      'script': 'super'
    }], // 下角标，上角标
    [{
      'indent': '-1'
    }, {
      'indent': '+1'
    }], // 缩进
    [{
      'direction': 'rtl'
    }], // 文字输入方向
    [{
      'size': ['small', false, 'large', 'huge']
    }], // 字体大小
    [{
      'header': [1, 2, 3, 4, 5, 6, false]
    }], // 标题
    [{
      'color': []
    }, {
      'background': []
    }], // 颜色选择
    [{
      'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial']
    }], // 字体
    [{
      'align': []
    }], // 居中
    ['clean'], // 清除样式,
    ['link', 'image'], // 上传图片、上传视频
  ]
  export default {
    name:'Editor',
    model: {
      prop: 'editorValue',
      event: 'input'
    },
    props:{
      editorValue:{
        type: String,
        default(){
          return ''
        }
      }
    },
    watch :{
      childValue(val) {
        this.$emit("input", val);
      },
      editorValue (val) {
        // console.log( '父组件看见的' + val);
        // console.log( '父组件看见的' + this.editorValue);
        this.childValue = val;
      }
    },
    data() {
      return {
        childValue: this.editorValue,
        editorOption: {
          placeholder: '请在这里输入',
          theme: 'snow', //主题 snow/bubble
          modules: {
            history: {
              delay: 1000,
              maxStack: 50,
              userOnly: false
            },
            toolbar: {
              container: toolbarOptions,
              handlers: {
                image: function(value) {
                  if(value) {
                    // 调用element的图片上传组件
                    document.querySelector('.avatar-uploader input').click()
                  } else {
                    this.quill.format('image', false)
                  }
                }
              }
            }
          }
        },
      }
    },
    methods: {
      beforeUpload (file) { },
      async getUploadUrl(fileData){
        var url = 'index/imgUpload';
        var formData = new FormData();
        //如果存在文件
        if(fileData.file) {
          formData.append("file", fileData.file, fileData.file.name);
          axios({
            url: url,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            method: "post",
            data: formData,
          }).then(res => {
            console.log(res);
            // 获取富文本组件实例
                let quill = this.$refs.QuillEditor.quill
                // 如果上传成功
                if (res.status==200) {
                  // 获取光标所在位置
                  let length = quill.getSelection().index;
                  // 插入图片，res为服务器返回的图片链接地址
                  quill.insertEmbed(length, 'image', res.data)
                  // 调整光标到最后
                  quill.setSelection(length + 1)
                }
          }).catch(error => {
            that.$message.error('网络错误');
          })
        }
      },
    },
  }
</script>

<style>

</style>